import React, { useRef, useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory } from "react-router-dom";
import {
  maxLengthMessage,
  minLengthMessage,
  requiredMessage,
} from "../../../../helpers/form-validation-message";
import "./cny-campaign-form.scss";
import { UserContext } from "../../../../provider/user-provider";
import SpinnerComponent from "../../../../templates/global-component/spinner-component/spinner-component";
import { validation_message } from "../../../../data/validation_message";
import { useLocation } from "react-router-dom";
import { checkTransactionIdAPI, submitCampaignAPI } from "./cny-campaign-api";
import { PopupContext } from "../../../../provider/popup-provider";

export default function CnyCampaignForm() {
  const { popup, setPopup, closePopup } = useContext(PopupContext);

  const { setValue, register, handleSubmit, errors } = useForm();
  const tooltip_edit_phone_number = useRef();
  const { userProfile } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const history = useHistory();

  const transaction_id = searchParams.get("transaction_id");

  useEffect(() => {
    const fetchData = async () => {
     await checkTransactionId();
    };

    fetchData();
  }, []);

  const checkTransactionId = async () => {
    const checkTransactionIdResult = await checkTransactionIdAPI(
      transaction_id
    );

    if (checkTransactionIdResult.status_code == 200) {
      setIsLoading(false);
    } else if (checkTransactionIdResult.status_code == 412) {
      // popup :Note
      let popupVal = Object.assign({}, popup);
      popupVal.open = true;
      popupVal.content = ["Please service your vehicle with TEMMY during the contest period to join the contest."];
      popupVal.img = "danger-icon";
      popupVal.buttons = [
        {
          type: "primary",
          text: "GO TO DASHBOARD",
          tap: () => {
            history.replace("/loyalty");
          },
        },
      ];

      setPopup(popupVal);
    } else if (checkTransactionIdResult.status_code == 500 || 404) {
      // popup :Note
      let popupVal = Object.assign({}, popup);
      popupVal.open = true;
      popupVal.content = ["We have received your entries.", "Stay tuned for the winner announcement!"];
      popupVal.img = "danger-icon";
      popupVal.buttons = [
        {
          type: "primary",
          text: "GO TO DASHBOARD",
          tap: () => {
            history.replace("/loyalty");
          },
        },
      ];

      setPopup(popupVal);
    } else if (checkTransactionIdResult.status_code == 502) {
      let popupVal = Object.assign({}, popup);
      popupVal.open = true;
      popupVal.content = ["Oops! Our server just encountered an error and was unable to complete your request.", "Please try again later. If the issue persists, feel free to contact our support.", "Apologies for the inconvenience!"];
      popupVal.img = "danger-icon";
      popupVal.buttons = [
        {
          type: "primary",
          text: "Close",
          tap: () => {
            closePopup();
          },
        },
      ];
      setPopup(popupVal);
      setIsLoading(false);
    } else {
      let popupVal = Object.assign({}, popup);
      popupVal.open = true;
      popupVal.content = ["Oppss...Something went wrong"];
      popupVal.img = "danger-icon";
      popupVal.buttons = [
        {
          type: "primary",
          text: "Close",
          tap: () => {
            closePopup();
            setIsLoading(false);
          },
        },
      ];

      setPopup(popupVal);
    }
  };

  const onSubmit = async (data) => {
    setIsLoading(true);

    if (!isLoading) {
      const submitResult = await submitCampaignAPI(data, transaction_id);

      if (submitResult.status_code == 200) {
        let popupVal = Object.assign({}, popup);
        popupVal.open = true;
        popupVal.content = ["We have received your entries.", "Stay tuned for the winner announcement!"];
        popupVal.img = "success-icon";
        popupVal.buttons = [
          {
            type: "primary",
            text: "GO TO DASHBOARD",
            tap: () => {
              history.push("/loyalty");
            },
          },
        ];

        setPopup(popupVal);
      } else if (submitResult.status_code == 404) {
        let popupVal = Object.assign({}, popup);
        popupVal.open = true;
        popupVal.content = ["Please service your vehicle with TEMMY during the contest period to join the contest."];
        popupVal.img = "danger-icon";
        popupVal.buttons = [
          {
            type: "primary",
            text: "GO TO DASHBOARD",
            tap: () => {
              history.push("/loyalty");
            },
          },
        ];

        setPopup(popupVal);
      } else if (submitResult.status_code == 412) {
        let popupVal = Object.assign({}, popup);
        popupVal.open = true;
        popupVal.content = ["We have received your entries.", "Stay tuned for the winner announcement!"];
        popupVal.img = "danger-icon";
        popupVal.buttons = [
          {
            type: "primary",
            text: "GO TO DASHBOARD",
            tap: () => {
              history.push("/loyalty");
            },
          },
        ];

        setPopup(popupVal);
      } else {
        let popupVal = Object.assign({}, popup);
        popupVal.open = true;
        popupVal.content = ["Oops! Our server just encountered an error and was unable to complete your request.", "Please try again later. If the issue persists, feel free to contact our support.", "Apologies for the inconvenience!"];
        popupVal.img = "danger-icon";
        popupVal.buttons = [
          {
            type: "primary",
            text: "Close",
            tap: () => {
              closePopup();
              setIsLoading(false);
            },
          },
        ];

        setPopup(popupVal);
      }
    }
  };

  const onTomTooltip = () => {
    tooltip_edit_phone_number.current.classList.add("active");
  };

  const onCloseTooltip = () => {
    tooltip_edit_phone_number.current.classList.remove("active");
  };

  const handleChange = (event, regex) => {
    const { value, name } = event.target;

    if (regex.test(value)) {
      setValue(name, value);
    } else {
      setValue(name, value.slice(0, -1));
    }
  };

  return (
    <section className="cny-campaign-form">
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-2">
              <div className="input-control">
                <input
                  type="text"
                  ref={register({
                    required: {
                      value: true,
                      message: "*Please provide your full name as per NRIC or Passport",
                    },
                    pattern: {
                      value: /^[a-zA-Z @\'\/]*$/,
                      message: "*Please provide your full name as per NRIC or Passport",
                    },
                    maxLength: {
                      value: 60,
                      message: "*Please provide your full name as per NRIC or Passport",
                    },
                  })}
                  id="full-name"
                  className={isLoading ? "pl-2 tom-input" : "tom-input"}
                  name="fullname"
                  placeholder="Full Name"
                  autoComplete="off"
                  defaultValue={userProfile.fullname}
                  maxLength="60"
                  disabled={isLoading}
                  onChange={(event) => handleChange(event, /^[a-zA-Z @\'\/]*$/)}
                />
                <small className="error">
                  <ErrorMessage errors={errors} name="fullname" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 py-2">
              <div className="user-number">
                <span className="mobile-no">
                  {userProfile.mobile_number}&nbsp;
                  <i
                    className="icon-information"
                    onMouseOver={() => onTomTooltip()}
                    onMouseOut={() => onCloseTooltip()}
                  ></i>
                  <div className="tooltip-tom" ref={tooltip_edit_phone_number}>
                    If you need to edit your <br /> number, please contact{" "}
                    <br />
                    <span className="text-primary">Syiok! Careline.</span>
                  </div>
                </span>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8">
							<p className="pl-2 pl-md-0">Touch ‘n Go eWallet Mobile Number</p>
							<div className="mobile-no-input">
								<div className="input-control" style={{ marginTop: "-1.5rem" }}>
									<select ref={register} name="mobile_ext_no" className="tom-input" id="mobile-ext-no" defaultValue={"60"}>
										<option value="60">MY: +60</option>
									</select>
									<input
										ref={register({
											required: {
												value: true,
												message: `*Please enter the mobile number linked to your Touch 'n Go eWallet account`,
											},
											pattern: {
												value: /^[0-9]{7,13}$/,
												message: `*Please enter the mobile number linked to your Touch 'n Go eWallet account`,
											},
										})}
										className={isLoading ? "pl-2 tom-input" : "tom-input"}
										id="mobile-no"
										name="mobile_no"
										placeholder="Mobile No."
										onChange={(event) => handleChange(event, /^[1-9]{1}[0-9]{0,10}$/)}
									/>
									<small className="error">
										<ErrorMessage errors={errors} name="mobile_no" />
									</small>
									<p style={{ fontSize: ".7rem" }} className="mt-3 pl-2 pl-md-0 mb-4">
										*Please ensure the mobile number for your Touch ‘n Go eWallet is correct for TotalEnergies to send the Touch ‘n Go eWallet Reload PIN to your number if you are selected as the winner.
									</p>
								</div>
							</div>
						</div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4">
              <div className="input-control">
                <p>
                  Which engine oil did you purchase to participate in the TotalEnergies 2025 CNY Service & Cash8ack Campaign?
                </p>
                <div className="form-check-inline">
                  <label className="tom-checkbox">
                    <input
                      type="radio"
                      ref={register({
                        required: {
                          value: true,
                          message: `*Please answer this question to proceed.`,
                        },
                      })}
                      name="answer"
                      value="TotalEnergies Quartz"
                      disabled={isLoading}
                    />
                    <span className="checkmark"></span>TotalEnergies Quartz
                  </label>
                </div>
                <div className="form-check-inline">
                  <label className="tom-checkbox">
                    <input
                      type="radio"
                      ref={register({
                        required: {
                          value: true,
                          message: `*Please answer the question.`,
                        },
                      })}
                      name="answer"
                      value="TotalEnergies Rubia"
                      disabled={isLoading}
                    />
                    <span className="checkmark"></span>TotalEnergies Rubia
                  </label>
                </div>
                <br />
                <small className="error" style={{ paddingLeft: "35px" }}>
                  <ErrorMessage errors={errors} name="answer" />
                </small>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-1">
              <div className="input-control">
                <label className="tom-checkbox">
                  <input
                    type="checkbox"
                    ref={register({
                      required: {
                        value: true,
                        message: '*Please accept the Terms and Conditions.',
                      },
                    })}
                    name="tnc"
                    disabled={isLoading}
                  />
                  <span className="checkmark"></span>By participating, I agree
                  to the{" "}
                  <a
                    href={"https://evp-api-totalenergies-dam-prod-cdn.wedia-group.com/api/media/play.html?PlayerUid=y60338b14d0a1d&MediaUid=8c55238e-c4d5-498a-b400-0654ecbae125"}
                    target="_blank"
                    className="text-primary"
                  >Terms & Conditions</a> and the{" "}
                  <a
                    href={"https://totalenergies.my/legal-notices"}
                    target="_blank"
                    className="text-primary"
                  >
                  Privacy Notice</a>.
                  <br />
                  <small className="error d-inline-block">
                    <ErrorMessage errors={errors} name="tnc" />
                  </small>
                </label>
              </div>
            </div>
            <div className="offset-1 offset-md-2 col-10 col-md-8 mb-4 text-center text-md-right">
              <button
                className="tom-button primary-button w-100"
                disabled={isLoading}
              >
                Submit
                {isLoading ? (
                  <SpinnerComponent
                    fontColor={"text-white"}
                    dimensions={{ mobile: "14px", desktop: "1rem" }}
                  ></SpinnerComponent>
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}
