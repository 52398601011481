import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { LoyaltyContext } from "../../../../../provider/loyalty-provider";

export default function RayaBannerComponent(props) {
  const { vehicles } = useContext(LoyaltyContext);
  const history = useHistory();

  const onJoinContest = () => {
    if (vehicles === null || vehicles.length <= 0) {
        history.push("/loyalty/add-new-vehicle");
    } else {
      let top_height = 0;
      window.innerWidth < 1023 ? (top_height = 45) : (top_height = 85);
      window.scrollTo({
        top: props.serviceRef.current.offsetTop - top_height,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <section>
      <div className="container px-3 py-0">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <img
              id="raya2025-loyalty-d"
              src="/assets/images/Raya2025-Dashboard_Desktop.jpg"
              className="d-none d-md-block raya-2025"
              alt="Raya Banner Desktop"
              width="100%"
              // onClick={onJoinContest}
              onClick={() => window.location.href = "https://totalenergies.my/about-totalenergies/media/celebrate-raya-totalenergies-service-your-car-get-exclusive-gifts"}
              style={{ cursor: "pointer" }}
            />
            <img
              id="raya2025-loyalty-m"
              src="/assets/images/Raya2025-Dashboard_Mobile.jpg"
              className="d-md-none raya-2025"
              alt="Raya Banner Mobile"
              width="100%"
              // onClick={onJoinContest}
              onClick={() => window.location.href = "https://totalenergies.my/about-totalenergies/media/celebrate-raya-totalenergies-service-your-car-get-exclusive-gifts"}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
